/* @import url(https://fonts.googleapis.com/css?family=Montserrat:400,700);
@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed:300italic); */

@media (max-width: 994px) {
    body {
        background-color: #ffffff;
        font-size: xx-large;
        color: black;
    }
    footer {
        font-size: medium;
    }
 }

body{
    background-color: #f0f4f3;
    color: black;
}

.link_ens {
  text-decoration: none;
  color: rgba(54, 113, 93, 1);
}

.link_ens :hover {
  text-decoration: none;
  color: #DC2B3D;
}
.container-lg{
  min-height: 500px;
  background-color: #ffffff;
}

.headTitle{
    /* color: #DC2B3D; */
    /* text-shadow: #f7fff5; */
    color: white;
    text-shadow: 0 0 5px rgba(255,255,255,.5), 0 0 1px;
    Font-family: Segoe UI, Frutiger, Dejavu Sans, Helvetica Neue, Arial, sans-serif;
    
}

.unselectable {
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

.bodoni {
    Font-family: Bodoni MT, Bodoni 72, Didot, Didot LT STD, Hoefler Text, Garamond, Times New Roman, serif;
}

.mt-n1 {
    margin-bottom: -0.25rem !important;
  }

.page-footer{
    background: #DC2B3D;
    color: white;
}

.divider:after,
.divider:before {
content: "";
flex: 1;
height: 1px;
background: #eee;
}

.navbar {
    /* fallback for old browsers */
    background: rgba(54, 113, 93, 1);    
  
    /* Chrome 10-25, Safari 5.1-6 */
    background: -webkit-linear-gradient(to right,
    transparent 20%, transparent 20% 40%, transparent 40% 60%, rgba(54, 113, 93, 1) 80%);
  
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    background: linear-gradient(to right,
    transparent 20%, transparent 20% 40%, transparent 40% 60%, rgba(54, 113, 93, 1) 80%);

  }
/* 
  .dropdown-menu {
    right: 0;
    overflow-wrap: break-word;
    width: 10rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  } */

  .blink_me {

    -webkit-animation-name: blinker;
    -webkit-animation-duration: 2s;
    -webkit-animation-timing-function: linear;
    -webkit-animation-iteration-count: infinite;
    
    -moz-animation-name: blinker;
    -moz-animation-duration: 2s;
    -moz-animation-timing-function: linear;
    -moz-animation-iteration-count: infinite;
    
    animation-name: blinker;
    animation-duration: 2s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
}

@-moz-keyframes blinker {  
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
}

@-webkit-keyframes blinker {  
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
}

@keyframes blinker {  
    0% { opacity: 1.0; }
    50% { opacity: 0.0; }
    100% { opacity: 1.0; }
}